import React, { useState, useEffect } from "react";
import { useLocation  } from 'react-router-dom';

const SearchChamelean = () => {
  const [loader, setLoader] = useState(true);
  const [provider, setProvider] = useState('all');

  const location = useLocation();

  useEffect(() => {

    const search = location.search;
    const param = search.startsWith('?') ? search.substring(1) : search;

    if(param)
    {
      setProvider(param);
    }

    const script = document.createElement("script");
    script.src = "https://static.myfinance.com/embed/inlineMedia_core.js";
    script.async = true;
    script.onload = () => {
      window.initializeInline({ loadInlineCss: true });
    };
    document.head.appendChild(script);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, []);


  return (
    <div className="banner_content">
      {/* <div className="custom-container">
        <br />
        <h3><b>Thank you for reaching out</b></h3>
        <h5>If you don’t see the deals on this page, our agent will give you a call. Please stay tuned.</h5>
      </div> */}
      <div className="custom-container">
        {
          (provider === 'all' || provider === 'spectrum') && (
            <div 
                class="myFinance-widget" 
                data-ad-id="c70b3378-54d5-4b82-961f-4a91e480d9e3" 
                data-campaign="chameleon-comlink-osb-spectrum">
            </div>
          )
        }
        
        {
          (provider === 'all' ||  provider == 'frontier') && (  
          
          <div class="myFinance-widget" data-ad-id="e4fcd41f-5310-4104-b2ce-4e73b05c00af" data-campaign="chameleon-quantumax-osb-frontier"></div>
        )}
        {
          (provider === 'all' ||  provider == 'verizon') &&  (

          <div class="myFinance-widget" data-ad-id="69186590-62f9-450d-982a-39ceb892236d" data-campaign="chameleon-quantumax-osb-verizon"></div>
        )}
        {
            (provider === 'all' ||  provider == 'att') && (  

          <div class="myFinance-widget" data-ad-id="23c1aec4-4cbd-4afd-9ce8-972c8fa18b21" data-campaign="chameleon-quantumax-osb-att"></div>
        )}
        {
          (provider === 'all' ||  provider == 'centurylink') && (  
          
          <div class="myFinance-widget" data-ad-id="da54aaf8-5947-4280-85da-8d13c792fbd9" data-campaign="chameleon-quantumax-osb-centurylink"></div>
        )}
        {
          (provider === 'all' ||  provider == 'cox') && (  

          <div class="myFinance-widget" data-ad-id="3092459a-7283-4f18-b8d8-e37cab80cda2" data-campaign="chameleon-quantumax-osb-cox"></div>
        )}
        {
          (provider === 'all' ||  provider == 'xfinity') && (  

          <div class="myFinance-widget" data-ad-id="1744c87b-0841-4cb3-810b-fbd3d75b52e3" data-campaign="chameleon-quantumax-osb-xfinity"></div>
        )}
        {
          (provider === 'all' ||  provider == 'tmobile') && (  

          <div class="myFinance-widget" data-ad-id="aef70206-2e5d-4a6e-90f0-ed2a260d4fc7" data-campaign="chameleon-quantumax-osb-tmobile"></div>
        )}
      </div>
    </div>
  );
};

export default SearchChamelean;
